/**
 * Created: 2022-01-11
 * Author: Florian Pürschel
 */
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from 'index';
import moment from 'moment-with-locales-es6';

/**
 * This class is used to set an entry to the database when a user visits the site.
 */
class Logger {
	/** Initialize function which call the set entry method. */
	static log(): void {
		this.setEntry();
	}

	/** Sets entry to the firebase firestore. */
	static async setEntry(): Promise<void> {
		await updateDoc(doc(db, 'visits', 'last'), {
			lastVisits: arrayUnion({
				date: moment().format('DD-MM-YYYY hh:mm:ss'),
				userAgent: navigator.userAgent,
			}),
		}).catch((err) => console.log(err));
		return;
	}
}

export default Logger;
